/* 
MAIN GLOBALS 
*/

html {
  scroll-behavior: smooth;
}

html.has-scroll-smooth {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body {
  background-color: #CFFAF6;
}

.responsive {
  width: 100%;
  height: auto;
}

.navigationBar{
  width: 100%;
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.navButtons {
  padding-top: 20px; 
  padding-right: 20px;
}

a:hover {
  color: #48FAE8;
}

.blur {
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
  width: 100%;
 }


.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: none;
    outline: none;
}

.section-padding-t {
  padding-top: 100px;
}
.section-padding-b {
  padding-bottom: 200px;
}
.section-padding-b100 {
  padding-bottom: 100px;
}


/*
SITE CONTENTS
*/


/*cover*/

.cover {
  height: 100vh;
  width: 100%;
  color: #0D2E2B;
}

#bottom {
  position: absolute;
  bottom: 3vw;
  left: 4vw;
}

.coverTitleTop {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-size: 11vw;
  font-weight: 200;
  line-height: 25vh;
}

.coverTitleBottom {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-size: 11vw;
  font-weight: 500;
  line-height: 25vh;
}

.explore-btn-text {
  font-family: Stolzl;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 29px;
  color: black;
  text-decoration: none;
  bottom: 20vh;
  position: absolute;
  transform: rotate(-90deg);
}

.explore-btn {
  display: flex;
  justify-content: flex-end;
}

/*end cover*/


/*headers*/

.headerText {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 77px;
  padding-left: 4vw;
}

/*end header*/



/*about section*/

.rectangle1 {
  width: 100%;
  height: min-content;
  background-color: #FEFEFE;
}

.rectangle1-section {
  margin-left: 4vw; 
  margin-right: 4vw; 
  margin-top: 50px;
}

.about-text {
  font-family: source-code-pro,monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;

  padding: 60px;
}

.about-picture {
  padding-right: 60px;
}

.resume-link {
  color: black;
}

/*end about section*/



/*projects section*/


.btn-primary {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  background: none;
  border-radius: 0px;
  border: 2px solid #0D2E2B;
  color: #0D2E2B;
  height: 60px;
  width: 200px;
}

.btn-primary:hover {
  background-color: #0D2E2B;
  border: 2px solid #0D2E2B;
}
.btn-primary:focus {
  background-color: #536160;
  border: 2px solid #536160;
}

.all-button {
  padding-right: 4vw;
}

.rectangle2 {
  background-color: #77E0D6;
  margin-right: -150px; 
  margin-bottom: 90px;
  height: min-content;
  min-height: 700px;
}

.rectangle2-section {
  margin-top: 50px;
}

.img1-projects {
  margin-top: 80px; 
  float: right;
}


.rectangle3 {
  background-color: #536160;
  margin-bottom: 90px;
  height: min-content;
  min-height: 700px;
  
}

.rectangle3-section {
  margin-top: 50px;
  width: 100%;
}

.img2-projects {
  margin-top: -100px; 
  float: right;
  margin-right: -200px;
}



.dark-heading-projects {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 58px;
  padding-left: 4vw;
  padding-top: 40px;
}

.dark-text-projects {
  font-family: source-code-pro,monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;

  padding: 40px;
  padding-left: 4vw;
}

.dark-date-projects {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;

  padding-left: 4vw;
}

.dark-btn-projects {
  font-family: Stolzl;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: black;
  text-decoration: none;
  padding-left: 4vw;
  padding-bottom: 40px;
  
}

.light-btn-projects {
  font-family: Stolzl;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  text-decoration: none;
  padding-left: 280px;
  padding-bottom: 40px;
  
}


.light-heading-projects {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 58px;
  padding-left: 280px;
  padding-top: 40px;
  color: #fff;
}

.light-text-projects {
  font-family: source-code-pro,monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: #fff;

  padding: 40px;
  padding-right: 0px;
  padding-left: 280px;
}

.light-date-projects {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #fff;

  padding-left: 280px;
}


.project-caption-text {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  transform: rotate(-90deg);
  position: absolute;
}

.project-caption-1 {
  margin-left: -80px;
  margin-top: -80px;
}

.project-caption-2 {
  margin-left: -140px;
  margin-top: -140px;
  white-space: nowrap;
}

.project-caption-3 {
  margin-left: -115px;
  margin-top: -110px;
}

.project-caption {
  display: inherit;
}



/*end projects section*/


/*robotics section*/

.first-spacer-robotics {
  padding-bottom: 100px;
}

.robot1 {
  padding: 4vw;
}

.robot2 {
  padding: 6vw;
}

.second-txt-robotics {
  padding-right: 4vw;
}

.img-scroll-animate {
  opacity: 0;
  transition: opacity 2s;
}

.img-scroll {
  opacity: 1;
}

/*end robotics section*/


/*contact section*/

.contact-section {
  background: #5CADA5;
  height: min-content;
  
}

.contact-header {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 77px;
  padding-left: 4vw;
  color: #fff;
}

.contact-text {
  font-family: stolzl, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 43px;
  color: #fff;

  padding-left: 4vw;
  margin: 0px;
  padding-top: 50px;
}

.contact-link {
  color: #fff;
  text-decoration: none;
}

.contact-item {
  margin-top: 20px;
  margin-bottom: 20px;
}

/*end contact section*/


/*
MOBILE
*/

@media (min-width: 990px) {
  .blur {
    background-color: inherit;  
    backdrop-filter: none;
    width: 100%;
   }

  .padding-200-r {
    padding-right: 200px;
  }

  .img2-projects {
    float: left;
  }
  
}

@media (max-width: 989px) {
  .light-heading-projects {
    padding-left: 4vw;
  }
  .light-date-projects {
    padding-left: 4vw;
  }
  .light-text-projects {
    padding-left: 4vw;
  }

  .contact-text {
    font-size: 4.5vw;
  }

  .coverTitleTop {
    font-family: stolzl, sans-serif;
    font-style: normal;
    font-size: 17vw;
    font-weight: 200;
    line-height: 16vh;
  }
  .coverTitleBottom {
    font-family: stolzl, sans-serif;
    font-style: normal;
    font-size: 19vw;
    font-weight: 500;
    line-height: 12vh;
    padding-bottom: 10vh;
  }

  .headerText {
    font-family: stolzl, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 38px;
    line-height: 77px;
    padding-left: 4vw;
  }

  .contact-header {
    font-family: stolzl, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 38px;
    line-height: 77px;
    padding-left: 4vw;
    color: #fff;
  }
  
  .section-padding-t {
    padding-top: 15px;
  }
  .section-padding-b {
    padding-bottom: 80px;
  }
  .section-padding-b100 {
    padding-bottom: 10px;
  }

  .about-picture {
    padding-right: 0px;
    padding: 20px;
  }

  .explore-btn {
    display: none;
  }

  .project-caption {
    display: none;
  }

}
